import React from 'react'
import PropTypes from 'prop-types'
import { Button, Stack, TextField, Typography, styled } from '@mui/material'
import loaderLight from '@assets/images/loader-light.svg'

const LookingStoreCartMsg = styled('span')(({ theme }) => ({
  fontSize: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem',
  },
}))

const InputPageContent = ({ email, errorMessage, handleInputChange, handleSubmit, loading, storeCartId }) => {
  const handleClick = ({ key, type }) => {
    if (type === 'click' || key === 'Enter') {
      handleSubmit(storeCartId.trim(), email.trim())
    }
  }

  return (
    <Stack mb={4} mt={4} p={6} sx={{ maxWidth: '800px', backgroundColor: 'white' }} spacing={3}>
      <Typography variant="title" mb={1} sx={{ fontSize: '2.5rem', fontWeight: 300 }}>
        STORE CART
      </Typography>
      <Typography>
        If you have recently visited a showroom and have created a store cart with one of our representatives please
        enter your cart information below.
      </Typography>
      <Typography>* Required field</Typography>
      <TextField
        value={storeCartId || ''}
        fullWidth
        sx={{ maxWidth: '400px' }}
        id="store-cart-id"
        inputProps={{ 'data-testid': 'id-input' }}
        disabled={loading}
        required
        label="Store Cart Number"
        onChange={e => handleInputChange({ target: { name: 'store-cart-id', value: e.target.value } })}
      />
      <TextField
        value={email || ''}
        fullWidth
        sx={{ maxWidth: '400px' }}
        id="email"
        inputProps={{ 'data-testid': 'email-input' }}
        disabled={loading}
        required
        label="Email"
        onChange={e => handleInputChange({ target: { name: 'email', value: e.target.value } })}
      />
      {errorMessage && <Typography sx={{ color: '#eb141f' }}>{errorMessage}</Typography>}
      <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" spacing={2}>
        <Button
          data-testid="submit"
          variant="contained"
          onClick={handleClick}
          sx={{ height: '3rem', width: { xs: '100%', md: '180px' } }}
        >
          {loading ? <img style={{ height: '80%' }} alt="loading" src={loaderLight} /> : 'FIND STORE CART'}
        </Button>
        {loading && <LookingStoreCartMsg>Looking for store cart...</LookingStoreCartMsg>}
      </Stack>
    </Stack>
  )
}

InputPageContent.propTypes = {
  email: PropTypes.string,
  errorMessage: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  storeCartId: PropTypes.string,
}

export default InputPageContent
